import ApiService from '@/api/new/services/api.service'
import i18n from '@/i18n'

const state = {
  file: [],
  filePages: {
    current: 0,
    total: 0,
    totalSegments: 0,
    pageSize: 0
  },
  revisionHistory: [],
  currentQaScores: [],
  currentSegmentName: null,
  currentSegmentId: null
}

const mutations = {
  setFile(state, file) {
    state.file = file
  },
  setFilePages(state, pages) {
    state.filePages.current = pages.current_page
    state.filePages.total = pages.last_page
    state.filePages.totalSegments = pages.total
    state.filePages.pageSize = pages.per_page
  },
  setCurrentPage(state, page) {
    state.filePages.current = page
  },
  setRevisionHistory(state, history) {
    state.revisionHistory = history
  },
  setCurrentSegmentName(state, name) {
    state.currentSegmentName = name
  },
  setCurrentSegmentId(state, id) {
    state.currentSegmentId = id
  },
  setQaScores(state, qaScores) {
    state.currentQaScores = qaScores
  }
}

const actions = {
  async getFile({ commit, dispatch }, { supplierId, taskId, currentPage }) {
    await ApiService.get(
      `suppliers/${supplierId}/tasks/${taskId}/review?page=${currentPage}&perPage=50`
    )
      .then(async (res) => {
        const file = res.data.data
        const pages = res.data.meta
        await commit('setFile', file)
        await commit('setFilePages', pages)
        await dispatch('getRevisionHistory')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getQaScores({ commit, rootGetters }, taskId) {
    const supplierId = rootGetters['supplier/supplierId']
    await ApiService.get(`suppliers/${supplierId}/tasks/${taskId}/qa`)
      .then((res) => {
        commit('setQaScores', res.data.data)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async filterFileSegments(
    { dispatch, commit, state },
    { supplierId, taskId, text, lang }
  ) {
    if (text.length === 0)
      return await dispatch('getFile', {
        supplierId,
        taskId,
        currentPage: state.filePages.current
      })
    await ApiService.get(
      `suppliers/${supplierId}/tasks/${taskId}/review/search?query=${text}&&language_code=${lang}`
    )
      .then(async (res) => {
        const file = res.data.data
        commit('setFile', file)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async confirmSegment({ rootState, rootGetters }, { translationId }) {
    const data = {
      data: {
        attributes: {
          translation_ids: [translationId]
        }
      }
    }
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplier'].id}/tasks/${rootState['supplierDashboard'].currentTask.id}/review/confirm-segment`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async unconfirmSegment({ rootState, rootGetters }, { translationId }) {
    const data = {
      data: {
        attributes: {
          translation_ids: [translationId]
        }
      }
    }
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplier'].id}/tasks/${rootState['supplierDashboard'].currentTask.id}/review/unconfirm-segment`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async confirmAllSegments({ dispatch, rootState, rootGetters, state }) {
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplier'].id}/tasks/${rootState['supplierDashboard'].currentTask.id}/review/confirm-all-segments`
    )
      .then(async () => {
        dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.confirm_segments') },
          { root: true }
        )
        await dispatch('getFile', {
          supplierId: rootGetters['supplier/supplier'].id,
          taskId: rootState['supplierDashboard'].currentTask.id,
          currentPage: state.filePages.current
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async unconfirmAllSegments({ dispatch, rootState, rootGetters, state }) {
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplier'].id}/tasks/${rootState['supplierDashboard'].currentTask.id}/review/unconfirm-all-segments`
    )
      .then(async () => {
        dispatch(
          'toast/success',
          { message: i18n.t('shared.toast.success.uncofirm_segments') },
          { root: true }
        )
        await dispatch('getFile', {
          supplierId: rootGetters['supplier/supplier'].id,
          taskId: rootState['supplierDashboard'].currentTask.id,
          currentPage: state.filePages.current
        })
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async finishRevision({ commit, rootGetters, rootState, state }) {
    const data = {
      data: {
        type: 'task_revision',
        attributes: {
          status: 'finished_by_supplier',
          changes: []
        }
      }
    }
    await ApiService.post(
      `suppliers/${rootGetters['supplier/supplier'].id}/tasks/${rootState['supplierDashboard'].currentTask.id}/review`,
      data
    ).catch((err) => {
      throw new Error(JSON.stringify(err))
    })
  },
  async updateSegment({ dispatch, rootGetters, rootState }, contents) {
    const data = {
      data: {
        type: 'bilingual',
        attributes: {
          status: 'draft',
          strings: contents
        }
      }
    }
    await ApiService.put(
      `suppliers/${rootGetters['supplier/supplier'].id}/tasks/${rootState['supplierDashboard'].currentTask.id}/review`,
      data
    )
      .then(async () => {
        await dispatch('getRevisionHistory')
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async getRevisionHistory({ commit, rootGetters, rootState, state }) {
    await ApiService.get(
      `suppliers/${rootGetters['supplier/supplier'].id}/tasks/${rootState['supplierDashboard'].currentTask.id}/review/history?page=${state.filePages.current}&perPage=50`
    )
      .then(async (res) => {
        const history = res.data.data
        await commit('setRevisionHistory', history)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  },
  async updateQualityAssurance(
    { state, dispatch, rootGetters },
    { taskId, score }
  ) {
    const supplierId = rootGetters['supplier/supplierId']
    const data = {
      data: {
        id: taskId,
        type: 'qa',
        attributes: {
          key_name: state.currentSegmentName,
          qa_score: {
            ...score
          }
        }
      }
    }
    await ApiService.post(`suppliers/${supplierId}/tasks/${taskId}/qa`, data)
      .then(async () => {
        dispatch(
          'toast/success',
          { message: i18n.t('customer.toast.success.update_qa') },
          { root: true }
        )
        await dispatch('getQaScores', taskId)
      })
      .catch((err) => {
        throw new Error(JSON.stringify(err))
      })
  }
}

const getters = {
  getQaScore: (state) => {
    if (!state.currentSegmentName || state.currentQaScores.length === 0) {
      return
    }
    const score = state.currentQaScores.find(
      (qa) => qa.attributes['key-name'] === state.currentSegmentName
    )
    return score && score.attributes.qa_score
  }
}

export const file = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
